import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import PropTypes from 'prop-types';
import RecipeEditorialList from '../components/recipe-editorial-list';

import { rhythm } from '../utils/typography';
import styled from 'styled-components';

const RecipeList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    list-style-type: none;
  }

  li:before {
    content: none;
  }
`;

function RecipeListForIngredient({ ingredient }) {
  return (
    <StaticQuery
      query={recipeListForIngredientQuery}
      render={(data) => {
        const recipes = data.allMarkdownRemark.edges;
        const filteredRecipes = recipes.filter(({ node }) => {
          console.log(node);
          return (
            node &&
            node.frontmatter &&
            node.frontmatter.recipe &&
            node.frontmatter.recipe.recipeIngredients &&
            node.frontmatter.recipe.recipeIngredients.some(
              (recipeIngredient) => recipeIngredient.name == ingredient
            )
          );
        });
        return (
          <div>
            {!!filteredRecipes.length && <h2>Recipes for {ingredient}:</h2>}
            <RecipeList>
              {filteredRecipes.map(({ node }, idx) => {
                const title = node.frontmatter.title || node.fields.slug;
                return (
                  <li key={idx}>
                    <RecipeEditorialList
                      recipe={node}
                      index={idx}
                    ></RecipeEditorialList>
                  </li>
                );
              })}
            </RecipeList>
            {!!filteredRecipes.length && (
              <hr
                style={{
                  marginBottom: rhythm(1),
                }}
              />
            )}
          </div>
        );
      }}
    />
  );
}

RecipeListForIngredient.propTypes = {
  ingredient: PropTypes.string,
};

const recipeListForIngredientQuery = graphql`
  query RecipeListForIngredientQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: { path: { eq: "recipe" } }
        frontmatter: { draft: { ne: true } }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            path
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            recipe {
              recipeIngredients {
                name
                amount
                preparation
                link
              }
            }
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 767) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default RecipeListForIngredient;
