import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import ProductCard from '../components/product-card';
import RecipeListForIngredient from '../components/recipe-list-for-ingredient';
import SEO from '../components/seo';
import { rhythm, scale } from '../utils/typography';

const Breadcrumb = styled.div`
  font-weight: 300;
`;

class IngredientPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const { previous, next } = this.props.pageContext;
    const seoTitle = `How to cook ${post.frontmatter.title}`;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={seoTitle} description={post.excerpt} />
        <Breadcrumb>
          <Link to="/">Home</Link>
        </Breadcrumb>
        <h1>{seoTitle}</h1>
        <p
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
            marginTop: rhythm(-1),
          }}
        >
          {post.frontmatter.date}
        </p>
        {post.frontmatter.recommendation && (
          <ProductCard
            name={post.frontmatter.recommendation.name}
            asin={post.frontmatter.recommendation.asin}
            image={post.frontmatter.recommendation.image.childImageSharp.sizes}
          />
        )}
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr />
        <RecipeListForIngredient ingredient={post.frontmatter.title} />
        <Bio />
        <ul
          style={{
            marginTop: `10px`,
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link
                to={'/' + previous.fields.path + previous.fields.slug}
                rel="prev"
              >
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={'/' + next.fields.path + next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    );
  }
}

export default IngredientPostTemplate;

export const pageQuery = graphql`
  query IngredientPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        recommendation {
          name
          image {
            childImageSharp {
              sizes(maxWidth: 630) {
                ...GatsbyImageSharpSizes
              }
            }
          }
          asin
        }
      }
    }
  }
`;
